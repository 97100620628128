import React from "react";
import CourseDetailComponent from "./CourseDetailComponent";

export default function Course(props) {
    let [course, setCourse] = React.useState(null);
    React.useEffect(() => {
        props.setPageTitle('Our courses');
        props.setPageDescription('Below given are the courses that we offer, currently. Please click on a course to learn more about it.');
    }, []);
    return <React.Fragment>
        {course ? <CourseDetailComponent item={course} setPageDescription={props.setPageDescription} setPageTitle={props.setPageTitle} setCourse={setCourse}/>
            : <section id="courses" className="courses">
                <div className="container" data-aos="fade-up">

                    <div className="row" data-aos="zoom-in" data-aos-delay="100">

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" onClick={() => setCourse('tbdp')}
                             style={{'cursor': 'pointer'}}>
                            <div className="course-item shadow-sm">
                                <img src='assets\\img\\courses\\tbdp.jpg' className="img-fluid" alt="..."/>
                                <div className="course-content">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4>TBDP</h4>
                                    </div>

                                    <h3>Total Brain Development Programme</h3>
                                    <p>TBDP has a well-defined approach to enhancing cognitive abilities and intelligence.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                             onClick={() => setCourse('mm')} style={{'cursor': 'pointer'}}>
                            <div className="course-item shadow-sm">
                                <img src='assets\\img\\courses\\mm.jpg' className="img-fluid" alt="..."/>
                                <div className="course-content">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4>Secret to Mental Maths</h4>
                                    </div>

                                    <h3>MatheMagics</h3>
                                    <p>Unveiling the power of numbers: a transformative journey through structured mental math mastery.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                             onClick={() => setCourse('tg')} style={{'cursor': 'pointer'}}>
                            <div className="course-item shadow-sm">
                                <img src='assets\\img\\courses\\tg.jpg' className="img-fluid" alt="..."/>
                                <div className="course-content">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4>Expertised Edu-Tutor</h4>
                                    </div>
                                    <h3>TuteGlow</h3>
                                    <p>Learning using cognitive didactic strategies to deepen understanding, improve retention, and apply knowledge effectively.</p>

                                </div>
                            </div>
                        </div>

                    </div>
                    <br/>
                    <br/>
                    <div className="row" data-aos="zoom-in" data-aos-delay="100">

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="course-item">
                                <img src='assets\\img\\courses\\poster\\tbdp.jpg' className="img-fluid" alt="..."/>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div className="course-item">
                                <img src='assets\\img\\courses\\poster\\mm.jpg' className="img-fluid" alt="..."/>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" >
                            <div className="course-item">
                                <img src='assets\\img\\courses\\poster\\tg.jpg' className="img-fluid" alt="..."/>
                            </div>
                        </div>

                    </div>

                </div>
            </section>}
    </React.Fragment>
}