import React from "react";

export default function RegistrationSubComponent(props) {
    const dict = {
        'tbdp': <React.Fragment>
{/* 
            <label>Enter three hobbies separated by a comma</label>
            <textarea placeholder={"Like this : hobby 1, hobby 2, hobby 3"} id="hobbies"
                      className="form-control" required></textarea> */}
        </React.Fragment>,
        'mathemagics': <React.Fragment>
            <select className={"form form-control"} id={"level"} required>
                <option value="">---------- choose MatheMagics level ----------</option>
                <option value="Beginner">Smart level(Beginner)</option>
                <option value="Advanced">Trigger level(Advanced)</option>
                <option value="Proficient">Shoot level(Proficient)</option>
            </select>
            <br/>
        </React.Fragment>,
        'tuteglow': <React.Fragment>
            <select className={"form form-control"} id={"level"} required>
                <option value="">---------- choose Academic level ----------</option>
                <option value="6">6<sup>th</sup> Standard/Grade</option>
                <option value="7">7<sup>th</sup> Standard/Grade</option>
                <option value="8">8<sup>th</sup> Standard/Grade</option>
                <option value="9">9<sup>th</sup> Standard/Grade</option>
            </select>
            <br/>
            <select className={"form form-control"} id={"subject"} required multiple>
                <option value="">---------- Click and hold ctrl to select multiple ----------</option>
                <option value="maths">Maths</option>
                <option value="science">Science (Physics,Chemistry,Biology)</option>
                <option value="physics">Physics</option>
                <option value="chemistry">Chemistry</option>
                <option value="biology">Biology</option>
            </select>
        </React.Fragment>
    }
    return <React.Fragment>
        <select className={"form form-control"} id={"mode"} required>
            <option value="">---------- choose a course mode ----------</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
        </select>
        <br/>
        {dict[props.item]}
    </React.Fragment>
}