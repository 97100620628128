import React from "react";
import { useRoutes } from 'hookrouter';
import Home from "./Components/Home";
import About from "./Components/About";
import Course from "./Components/Course";
import Gallery from "./Components/Gallery";
import Register from "./Components/Register";
import Contact from "./Components/Contact";

function App() {
    let url = 'https://server.elvesonthecloud.com/abkiab/';
    let [pageTitle, setPageTitle] = React.useState(null);
    let [pageDescription, setPageDescription] = React.useState(null);
    const currentDate = new Date();
    const targetDate = new Date('2024-09-29');
    const [isPaused, setIsPaused] = React.useState(false);
    const mouseIn = () => {
        setIsPaused(true);
    };
    const mouseOut = () => {
        setIsPaused(false);
    };
    const isBeforeTargetDate = currentDate < targetDate;
    const routes = {
        '/': () => <Home setPageDescription={setPageDescription} setPageTitle={setPageTitle} />,
        '/about': () => <About setPageDescription={setPageDescription} setPageTitle={setPageTitle} />,
        '/course': () => <Course setPageDescription={setPageDescription} setPageTitle={setPageTitle} />,
        '/gallery': () => <Gallery setPageDescription={setPageDescription} setPageTitle={setPageTitle} />,
        '/register': () => <Register url={url} setPageDescription={setPageDescription} setPageTitle={setPageTitle} />,
        '/contact': () => <Contact url={url} setPageDescription={setPageDescription} setPageTitle={setPageTitle} />,
    };
    const routeResults = useRoutes(routes);
    React.useEffect(() => {
        if (document.getElementById('openModal')) {
            const openModal = document.getElementById('openModal');
            const modalBackground = document.getElementById('modalBackground');
            const closeModal = document.getElementById('closeModal');
            openModal.addEventListener('click', function (event) {
                event.preventDefault();
                modalBackground.style.display = 'block';
            });
            window.addEventListener('click', function (event) {
                if (event.target === modalBackground) {
                    modalBackground.style.display = 'none';
                }
            });
            closeModal.addEventListener('click', function () {
                modalBackground.style.display = 'none';
            });
        }
    })
    return (
        <React.Fragment>
            {pageTitle ? <div className="breadcrumbs" style={{ 'marginTop': '1.5rem' }} data-aos="fade-in">
                <div className="container">
                    <h1>{pageTitle}</h1>
                    <h5>{pageDescription}</h5>
                </div>
            </div> : <React.Fragment>
                <div className="breadcrumbs" style={{ 'marginTop': '1.5rem', 'maxHeight': '2rem' }} data-aos="fade-in">
                    <div className="container banner" style={{ 'position': 'relative', 'overflow': 'hidden' }}>
                        <h5 style={{ 'whiteSpace': 'nowrap' }}>
                            <marquee className={`${isPaused ? 'paused' : ''}`} onMouseEnter={mouseIn} onMouseLeave={mouseOut}>
                                {isBeforeTargetDate ? <>Join ABKIAB's Grand Gathering Eve! Click <a href="#" id="openModal">Here</a> to view more details.</> :
                                    "Welcome to Abdul Kalam Institute For Accelerated Brains. 'The hardest you practice, the luckiest you will be...', We ABKIAB team welcomes you🌹"}
                            </marquee>
                        </h5>
                    </div>
                </div>
            </React.Fragment>}
            {routeResults}
            <br />
            <br />
            <br />
            <input type="hidden" id={"script-trigger"} />
        </React.Fragment>
    );
}

export default App;
