import React from "react";

export default function Gallery(props) {
    const videos = [
        { id: "6yQl0x6oFNo", active: true },
        { id: "sawojsyiu40", active: false },
        { id: "1PpJg3cMdU0", active: false },
        { id: "boJ6j9mLFs4", active: false },
        { id: "XYzCI-G3Dzc", active: false },
        { id: "LGibORE2Wm8", active: false },
        { id: "AOLlA0JDMaM", active: false },
        { id: "bYoZqLFuGak", active: false },
        { id: "R1uyeqE9G20", active: false },
        { id: "zmu3-jc8sjA", active: false },
        { id: "GemEH68gZIw", active: false },
        { id: "qWg2TbCm8ZM", active: false },
        { id: "_V_fUmzfzQk", active: false },
        { id: "PekipzKAuuc", active: false },
        { id: "NmmEki2sh2c", active: false },
        { id: "dGZjXNTaUuA", active: false },
        { id: "r90ncqEprNs", active: false },
        { id: "kd_MZbkugy8", active: false },
        { id: "6bgqMBxuecc", active: false },
        { id: "fATAfKadzQI", active: false },
        { id: "KiXgzLKxpwQ", active: false },
        { id: "gZNmMVBwbjQ", active: false },
        { id: "9ElMZZ2ngGY", active: false },
        { id: "Nmxvh277vTI", active: false },
        { id: "dGZjXNTaUuA", active: false }
    ]
    React.useEffect(() => {
        props.setPageTitle('Our gallery');
        props.setPageDescription('Please have a look at our gallery to see images and videos of our learning programmes.');
    }, []);
    return <React.Fragment>
        <br />

        <section id="gallery" className="padding">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="btn our-btn active" id="pills-img-tab" data-toggle="pill" href="#pills-img" role="tab" aria-controls="pills-img" aria-selected="true">Images</a>
                        </li>
                        <li className="nav-item">
                            <a className="btn our-btn" id="pills-vid-tab" data-toggle="pill" href="#pills-vid" role="tab" aria-controls="pills-vid" aria-selected="false">Videos</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane show active" id="pills-img" role="tabpanel" aria-labelledby="pills-img-tab">
                        <div className="row text-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/0.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/0.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/1.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/1.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/2.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/2.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/3.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/3.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/4.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/4.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/5.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/5.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/6.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/6.png"} alt="..." />
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/7.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/7.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/8.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/8.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/9.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/9.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/10.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/10.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/11.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/11.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/12.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/12.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/13.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/13.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/14.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/14.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/15.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/15.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/16.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/16.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/17.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/17.png"} alt="..." />
                                </a>
                            </div>
                            {/* <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/18.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/18.png"} alt="..." />
                                </a>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <a href={"assets/img/gallery/19.png"} className="d-block mb-4 h-100">
                                    <img className="img-fluid img-thumbnail img-gallery" src={"assets/img/gallery/19.png"} alt="..." />
                                </a>
                            </div> */}

                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-vid" role="tabpanel" aria-labelledby="pills-vid-tab">
                        <div className="container">

                            <div className="col-lg-10 mx-auto py-4">
                                <div className="embed__container">
                                    <div id="player"></div>
                                </div>
                                <div className="carousel__wrap mt-3">
                                    <div className="owl-carousel">
                                        {videos.map((video, index) => (
                                            <div
                                                key={index}
                                                data-video={video.id}
                                                className={`item video-thumb ${video.active ? 'active' : ''}`}
                                            >
                                                {video.active && <input type="hidden" id="youtube-active-video" value={video.id} />}
                                                <img
                                                    src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`}
                                                    alt="..."
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment >
}

