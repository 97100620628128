import React from "react"

export default function CourseDetailComponent(props) {
    const dict = {
        'tbdp': {
            'img': 'assets\\img\\courses\\tbdp.jpg',
            'poster': 'assets\\img\\courses\\poster\\tbdp.jpg',
            'name': 'Total Brain Development Programme',
            'fee': 'SAR 12300',
            'seats': '30',
            'description3': `
            Optimizing Brain Coordination*
            Boosting Intelligent Quotient (IQ)*
            Abacus Tool Training for Right Brain Development*
            Brain Gym Exercising*
            Expertly Curated Exercises*
            Brain Quiz Puzzling Segment*
            Holistic Cognitive Enhancement*
            Unleash Cognitive Potential
             `,
            'description1': `Elevate your child's cognitive abilities with our comprehensive approach to brain development. Our program offers a range of engaging activities designed to unlock full mental potential.`,
            'description2': `
            Embark on a journey of growth through our Abacus tool training, meticulously crafted to enhance right brain development. 
            Discover the power of Brain Gym exercising, a methodical regimen tailored to different levels. As you progress, you'll experience the benefits of improved cognitive agility, focus, and overall brain health. Our expertly curated exercises stimulate brain function, allowing you to tackle tasks with newfound clarity and efficiency.
            Challenge your child's intellect with our Brain Quiz puzzling segment. Engage in mind-teasing activities such as anagrams and crossword puzzles, offering mental gymnastics that keep their brain active and agile. These dynamic quizzes are like push-ups for their brain, fostering quick thinking and cognitive flexibility.
            At ABKIAB, we believe in a holistic approach to cognitive enhancement. Our innovative techniques, including Abacus training, Brain Gym exercises, and Brain Quiz puzzling, converge to create a well-rounded program that nurtures every facet of a child's intellectual growth. Join us on this transformative journey to unleash cognitive potential and thrive in a world of endless possibilities.
                 `,
        },
        'mm': {
            'img': 'assets\\img\\courses\\mm.jpg',
            'poster': 'assets\\img\\courses\\poster\\mm.jpg',
            'name': 'MatheMagics',
            'fee': 'SAR 12300',
            'seats': '30',
            'description3': `
            Beginner Smart Level*
            Advanced Trigger Level*
            Master Shoot Level
            `,
            'description1': `The three-level structure of ABKIAB's Mental Mathematics course  called MatheMagics’—beginner Smart level, Advanced Trigger level, and Master Shoot level —suggests a structured and progressive approach to teaching mental math skills.`,
            'description2': `
            Here's a breakdown of what each level of ABKIAB's MatheMagics entails:
               * The Beginner Smart level is designed for students who are just starting to develop their mental math skills. It covers foundational concepts and techniques, such as basic addition, subtraction, multiplication, and division strategies. The focus is on building a strong numerical foundation and improving mental calculation speed.

                *The Advanced Trigger level is for students who have already grasped the basics and are ready to delve deeper into more complex mental math techniques. This level covers topics like mental calculations involving larger numbers, multi-digit multiplication, division with remainders, and possibly the introduction to techniques like squaring numbers mentally.

                *The Master level, referred to as "Shoot," suggests a higher level of proficiency. This level involves advanced mental math skills, including techniques for rapid calculations, handling percentages, square roots, and even more intricate mathematical operations. Students at this level also work on solving math problems involving real-world scenarios, challenging their critical thinking and problem-solving abilities.

                *The naming convention "Smart, Trigger, and Shoot" indicates a sense of progression and achievement, with each level building upon the skills acquired in the previous one. Overall, the structure seems to provide a clear path for students to gradually enhance their mental math abilities, catering to different skill levels, and ensuring a comprehensive understanding of mental calculations. It's important for parents and students to inquire further about the curriculum, teaching methods, and outcomes of each level to determine whether the course aligns with their goals and expectations.
         `
        },
        'tg': {
            'img': 'assets\\img\\courses\\tg.jpg',
            'poster': 'assets\\img\\courses\\poster\\tg.jpg',
            'name': 'TuteGlow',
            'fee': 'SAR 12300',
            'seats': '30',
            'description3': `
            Experienced Edu-Tutor : Personalized academic guidance with our Expertised Edu Tutors for comprehensive learning and skill development.*
            Concept-Wise Training : ABKIAB's cognitive didactic strategies involves teaching techniques that not only deliver information but also engage students' cognitive abilities such as critical thinking, problem-solving, and analytical skills. These strategies are intended to enhance the learning experience by promoting a deeper understanding of the subject matter and facilitating effective retention and application of knowledge.*
            Enhanced learning on multiple curricula : We combine advanced cognitive learning strategies with effective teaching techniques, fostering a deep understanding of all syllabus concepts. By integrating these strategies into the educational framework, students receive a holistic and enriched learning experience that transcends traditional boundaries, enabling them to excel in various academic contexts.*
            Mathematics and Science Subjects : Utilizing the fundamental principles of these subjects to catalyze innovation and intricate problem-solving across the spectrum of STEM (Science, Technology, Engineering, and Mathematics) disciplines.
                        `,
            'description1': `This course is intended to enhance the learning experience by promoting a deeper understanding of the subject matter and facilitating effective retention and application of knowledge.`,
            'description2': `
            Our institute offers a specialized program designed to deliver precisely this kind of comprehensive education. 
            Empower students to master diverse curricula such as Kerala, CBSE, ICSE, IGCSE, IB, and the British Curriculum through the implementation of cognitive didactic strategies. 
            These specialized methods combine advanced cognitive learning strategies with effective teaching techniques, fostering a deep understanding
             of all syllabus concepts. By integrating these strategies into the educational framework, students receive a holistic and enriched learning experience that transcends 
             traditional boundaries, enabling them to excel in various academic contexts.
             ` }
    }
    React.useEffect(() => {
        props.setPageTitle(dict[props.item].name)
        props.setPageDescription('Read more about the course, below...')
    }, [])
    return < section id="blog" className="blog" data-aos="fade-up" >
        <div className="container" data-aos="fade-up">
            <div className="row">
                <div className="col-lg-8 entries">
                    <article className="entry entry-single">


                        <div className="row d-flex justify-content-between">
                            <div className="img-thumbnail shadow-sm" id="course-detail-img" style={{ 'width': '20%' }}>
                                <img src={dict[props.item].img} alt="" className="img-fluid" />
                            </div>
                            <div className="" style={{ 'width': '75%' }}>
                                {navigator.userAgent.match(/Mobile/) ? <React.Fragment>
                                    <br />
                                    <h6 id="course-detail-name">
                                        <a href="#!"><strong>{dict[props.item].name}</strong></a>
                                    </h6>
                                </React.Fragment> : <h2 id="course-detail-name">
                                    <a href="#!"><strong>{dict[props.item].name}</strong></a>
                                </h2>}
                                <p>
                                    {dict[props.item].description1}
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="entry-content">
                            <p>
                                {props.item == 'mm' ? dict[props.item].description2.split('*').map(i => {
                                    return <React.Fragment key={i}>{i}<br /><br /></React.Fragment>
                                }) : dict[props.item].description2}
                            </p>
                            {props.item == 'tg' ? <section id="services" className="services">
                                <div className="container" data-aos="fade-up">
                                    <div className="gy-4">
                                        {dict[props.item].description3.split('*').map(i => {
                                            return <div className="col-lg-3" key={i} data-aos="fade-up" data-aos-delay="200" style={{ 'maxHeight': '50%', 'width': '100%' }}>
                                                <div className="row d-flex service-box purple" >
                                                    <h3>{i.split(':')[0]}</h3>
                                                    <p>{i.split(':')[1]}</p>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </section>
                                : props.item == 'mm' ? <React.Fragment>
                                    <div className="row d-flex justify-content-center">
                                        <div className="course-info d-flex justify-content-between align-items-center our-team" style={{ 'width': '33%' }}>
                                            <video playsInline className="img-fluid" controls="controls" preload="metadata">
                                                <source src="https://server.elvesonthecloud.com/static/abkiab/1.mp4" type="video/mp4">
                                                </source>
                                            </video>
                                        </div>
                                        <br />
                                        <br />
                                        <div className="course-info d-flex justify-content-between align-items-center our-team" style={{ 'width': '33%' }}>
                                            <video playsInline className="img-fluid" controls="controls" preload="metadata">
                                                <source src="https://server.elvesonthecloud.com/static/abkiab/2.mp4" type="video/mp4">
                                                </source>
                                            </video>
                                        </div>
                                        <br />
                                        <br />
                                        <div className="course-info d-flex justify-content-between align-items-center our-team" style={{ 'width': '33%' }}>
                                            <video playsInline className="img-fluid" controls="controls" preload="metadata">
                                                <source src="https://server.elvesonthecloud.com/static/abkiab/3.mp4" type="video/mp4">
                                                </source>
                                            </video>
                                        </div>
                                    </div>
                                </React.Fragment> : <blockquote>
                                    <div className="list-group">{dict[props.item].description3.split('*').map(i => {
                                        return <span key={i} className="list-group-item list-group-item-action"><b><h5>{i}</h5></b></span>
                                    })}</div>
                                </blockquote>
                            }
                        </div>
                    </article>
                </div>
                <div className="col-lg-4">
                    <div className="sidebar">
                        <div className="sidebar-item categories">
                            <a href="/register" className="btn our-btn d-flex justify-content-between align-items-center">
                                <i className="bi bi-award"></i>
                                Click to register now!
                            </a>
                            <br />
                            <a href="#!" className="btn btn-outline-primary d-flex justify-content-between align-items-center" onClick={() => {
                                props.setCourse(null);
                                props.setPageTitle('Our courses');
                                props.setPageDescription('Below given are the courses that we offer, currently. Please click on a course to learn more about it.');
                            }}>
                                <i className="bi bi-arrow-left"></i>
                                Back to all courses
                            </a>
                            <br />
                        </div>
                        <h3 className="sidebar-title"></h3>
                        <div className="sidebar-item tags">
                            <img src={dict[props.item].poster} className="img-fluid" alt="..." />
                            <br />
                            <br />

                        </div>
                    </div>
                </div>
            </div>
        </div >
    </section >

}